export interface ISensor {
    id: string;
    name: string;
    isOnline: boolean;
    metadata: { [key: string] : string };
    readings: ISensorReading[];
    configuration?: ILoraCommand[] | null;
}

export interface ISensorReading {
    id: string;
    name: string;
    type: DataType;
    units?: string | null;
    value?: any | null;
    timestamp?: number | null;
    isDebug?: boolean | null;
}

export interface ILoraCommand {
    id: number;
    name: string;
    params: ILoraCommandParam[]
}

export interface ILoraCommandParam {
    id: string;
    name: string;
    minValue: number;
    maxValue: number;
    size: number;
    value: number;
}

export enum DataType {
    Boolean,
    Integer,
    Hex,
    Decimal,
    Gps,
    Leak
}

export interface ILeak {
    intensity: number,
    lat: number,
    lon: number
}